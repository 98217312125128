import { render, staticRenderFns } from "./tableBeeProductsManagementComponent.vue?vue&type=template&id=461f824c&scoped=true"
import script from "./tableBeeProductsManagementComponent.vue?vue&type=script&lang=js"
export * from "./tableBeeProductsManagementComponent.vue?vue&type=script&lang=js"
import style0 from "./tableBeeProductsManagementComponent.vue?vue&type=style&index=0&id=461f824c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "461f824c",
  null
  
)

export default component.exports