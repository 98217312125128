<template>
    <v-dialog full-screen scrollable persistent :value="true">
        <v-card>
            <v-card-title :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']" class="card-header">
                {{ this.$t('generic.lang_wareGroupAutomation') }}
            </v-card-title>

            <v-card-text class="pa-0 pt-3">
                <v-form v-model="valid" lazy-validation ref="automationForm">
                <v-data-table :key="updated" :headers="headers" :items="days" :loading="loading" hide-default-footer
                    :no-data-text="$t('generic.lang_noEntriesFound')">
                    
                        <template v-slot:item.day="{ item }">
                        <v-checkbox :ripple="false" class="mb-3" :value="true" v-model="item.active"
                            :label="weekdays[Number(item.day)].name" @input="resetDay( $event ,item)" />
                    </template>
                    <template v-slot:item.openingMorning="{ item }">
                        <v-row align="center" justify="center">
                            <v-col cols="12" sm="2" class="text-right">
                                <v-simple-checkbox :ripple="false" @input="resetTime( $event ,item.openingMorning)"
                                    :disabled="!item.active" color="primary" v-model="item.openingMorning.active"
                                    class="mx-auto" :value="true" />
                            </v-col>
                            <v-col cols="12" sm="5" class="pt-8">

                                <BaseTimeInput :rules="[v => (item.openingMorning.active && item.active)?!!v || $t('generic.lang_required'):true]" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                    @focus="showTouchKeyboard" clearable
                                    :disabled="!item.active || !item.openingMorning.active"
                                    v-model="item.openingMorning.from" class="pb-0 pt-0 ma-0 mx-0" outlined dense
                                    :label="$t('generic.lang_from')" />
                            </v-col>

                            <v-col cols="12" sm="5" class="pt-8">
                                <BaseTimeInput :rules="[v => (item.openingMorning.active && item.active)?!!v || $t('generic.lang_required'):true]" :label="$t('generic.lang_to')" dense outlined
                                    clearable class="pb-0 pt-0 ma-0 mx-0" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                    :disabled="!item.active || !item.openingMorning.active" @focus="showTouchKeyboard"
                                    v-model="item.openingMorning.to" />
                            </v-col>
                        </v-row>
                    </template>

                    <template v-slot:item.openingAfternoon="{ item }">
                        <v-row align="center" justify="center">
                            <v-col cols="12" sm="2" class="text-right">
                                <v-simple-checkbox :ripple="false"
                                    :disabled="!item.active" color="primary" v-model="item.openingAfternoon.active"
                                    class="mx-auto" :value="true" />
                            </v-col>
                            <v-col cols="12" sm="5" class="pt-8">

                                <BaseTimeInput :rules="[v => (item.active && item.openingAfternoon.active)?!!v || $t('generic.lang_required'):true]" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                    @focus="showTouchKeyboard" :disabled="!item.active || !item.openingAfternoon.active"
                                    clearable v-model="item.openingAfternoon.from" class="pb-0 pt-0 ma-0 mx-0" outlined
                                    dense :label="$t('generic.lang_from')" />
                            </v-col>

                            <v-col cols="12" sm="5" class="pt-8">
                                <BaseTimeInput :rules="[v => (item.active && item.openingAfternoon.active)?!!v || $t('generic.lang_required'):true]" :label="$t('generic.lang_to')" dense outlined
                                    class="pb-0 pt-0 ma-0 mx-0" :data-layout="KEYBOARD.KEYSETS.NORMAL" clearable
                                    :disabled="!item.active || !item.openingAfternoon.active" @focus="showTouchKeyboard"
                                    v-model="item.openingAfternoon.to" />
                            </v-col>
                        </v-row>
                    </template>

                    <template v-slot:item.active="{ item }">
                        <span v-show="false"> {{ item.closedDay }}</span>
                    </template>
                </v-data-table>
            </v-form>
            </v-card-text>

            <v-card-actions class="card-footer pt-2 pb-2">
                <v-btn @click="closeConfigDialog" color="error" text>{{ this.$t('generic.lang_cancel') }}</v-btn>

                <v-spacer />

                <v-btn @click="update" color="primary" elevation="0" :disabled="!valid" :loading="loading">
                    {{ this.$t('generic.lang_apply') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import mixin from "@/mixins/KeyboardMixIns";
import BaseTimeInput from "@/components/common/BaseTimeInput.vue";
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
import { active } from "d3";

export default {
    name:"ItemGroupAutomationDialog",
    components: {
        BaseTimeInput
    },
    props: {
        groupId: {
            type: Number,
            default: 0
        },
    },
    mixins: [mixin],
    watch:{
        days:{
            handler(v){
                this.updated = Date.now();
            },
            deep: true,
        }
    },
    data() {
        return {
            loading: false,
            valid: false,
            updated: 0,
            defaultDayData: {
                active: false,
                openingMorning: { active: false, from: "", to: "" },
                openingAfternoon: { active: false, from: "", to: "" }
            },
            days: [
                {
                    day: 0,
                    name: "monday",
                    active: false,
                    openingMorning: { active: false, from: "", to: "" },
                    openingAfternoon: { active: false, from: "", to: "" }
                },
                {
                    day: 1,
                    name: "tuesday",
                    active: false,
                    openingMorning: { active: false, from: "", to: "" },
                    openingAfternoon: { active: false, from: "", to: "" }
                },
                {
                    day: 2,
                    name: "wednesday",
                    active: false,
                    openingMorning: { active: false, from: "", to: "" },
                    openingAfternoon: { active: false, from: "", to: "" }
                },
                {
                    day: 3,
                    name: "thursday",
                    active: false,
                    openingMorning: { active: false, from: "", to: "" },
                    openingAfternoon: { active: false, from: "", to: "" }
                },
                {
                    day: 4,
                    name: "friday",
                    active: false,
                    openingMorning: { active: false, from: "", to: "" },
                    openingAfternoon: { active: false, from: "", to: "" }
                },
                {
                    day: 5,
                    name: "saturday",
                    active: false,
                    openingMorning: { active: false, from: "", to: "" },
                    openingAfternoon: { active: false, from: "", to: "" }
                },
                {
                    day: 6,
                    name: "sunday",
                    active: false,
                    openingMorning: { active: false, from: "", to: "" },
                    openingAfternoon: { active: false, from: "", to: "" }
                }
            ]
        }
    },
    computed: {
        weekdays() {
            return [
                {
                    name: this.$t('generic.Montag'),
                    value: 'monday',
                },
                {
                    name: this.$t('generic.Dienstag'),
                    value: 'tusday',
                },
                {
                    name: this.$t('generic.Mittwoch'),
                    value: 'wednesday',
                },
                {
                    name: this.$t('generic.Donnerstag'),
                    value: 'thursday',
                },
                {
                    name: this.$t('generic.Freitag'),
                    value: 'friday',
                },
                {
                    name: this.$t('generic.Samstag'),
                    value: 'saturday',
                },
                {
                    name: this.$t('generic.Sonntag'),
                    value: 'sunday'
                }
            ];
        },
        headers() {
            return [
                {
                    text: this.$t("generic.lang_day"),
                    value: "day",
                    align: 'center',
                    sortable: false,
                    width: 150,
                },
                {
                    text: this.$t('reservation.lang_mornings'),
                    value: "openingMorning",
                    align: 'center',
                    sortable: false,
                    width: 300,
                },
                {
                    text: this.$t('reservation.lang_afternoon'),
                    value: "openingAfternoon",
                    align: 'center',
                    sortable: false,
                    width: 300,
                },
                {
                    text: "",
                    value: "active",
                    align: 'center',
                    sortable: false,
                    hide: true,
                    width: 50,
                }
            ]
        }
    },
    methods: {
        resetDay(val ,day) {
            day.active =  val;
            day.openingMorning.active = false;
            day.openingAfternoon.active = false;
        },
        resetTime(val ,time) {
            time.active = val
        },
        closeConfigDialog() {
            this.$emit('close')
        },
        parseDays(data = {}, mode = 'update') {
            if (!data)
                return;

            if (mode === 'get') {
                this.days.forEach((day, index) => {
                    if (data[day.name]) {
                        day.active = data[day.name].active
                        if (data[day.name].openingMorning) {
                            day.openingMorning.active = true;
                            day.openingMorning.from = data[day.name].openingMorning.from
                            day.openingMorning.to = data[day.name].openingMorning.to
                        }

                        if (data[day.name].openingAfternoon) {
                            day.openingAfternoon.active = true;
                            day.openingAfternoon.from = data[day.name].openingAfternoon.from
                            day.openingAfternoon.to = data[day.name].openingAfternoon.to
                        }
                    }
                })
                return;
            }
            const automationData = {};
            this.days.forEach((day, index) => {
                automationData[day.name] = {
                    active: day.active
                };
                if (day.openingMorning.active) {
                    automationData[day.name]['openingMorning'] = {
                        from: day.openingMorning.from,
                        to: day.openingMorning.to
                    }
                }

                if (day.openingAfternoon.active) {
                    automationData[day.name]['openingAfternoon'] = {
                        from: day.openingAfternoon.from,
                        to: day.openingAfternoon.to
                    }
                }
            })

            return automationData;
        },
        getData() {
            this.loading = true;
            this.axios.post(ENDPOINTS.TABLEBEE.PRODUCTSGROUP.AUTOMATION.GET, {
                groupId: this.groupId
            }).then((res) => {
                if (res.data.STATUS) {
                    this.parseDays(res.data.automationData, 'get')
                } else {
                    Events.$emit("showSnackbar", {
                        color: "error",
                        message: this.$t('generic.lang_errorOccurred'),
                    });
                }
            }).catch((err) => {
                Events.$emit("showSnackbar", {
                    color: "error",
                    message: err.message,
                });
            }).finally(() => {
                this.loading = false;
            })
        },
        update() {

            if(!this.$refs.automationForm.validate())
                return;
                


            this.loading = true;
            const automationData = this.parseDays();
            this.axios.post(ENDPOINTS.TABLEBEE.PRODUCTSGROUP.AUTOMATION.UPDATE, {
                groupId: this.groupId,
                automationData: automationData
            }).then((res) => {
                if (res.data.status) {
                    this.closeConfigDialog()
                    Events.$emit("showSnackbar", {
                        color: "success",
                        message: this.$t('generic.lang_actionSuccessful'),
                    });
                } else {
                    Events.$emit("showSnackbar", {
                        color: "error",
                        message: this.$t('generic.lang_errorOccurred'),
                    });
                }
            }).catch((err) => {
                Events.$emit("showSnackbar", {
                    color: "error",
                    message: err.message,
                });
            }).finally(() => {
                this.loading = false;
            })
        },
    },
    mounted() {
        this.getData();
    }
}

</script>